import React from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {slugify} from "../helpers"
import ArticleLink from "../components/ArticleLink";

export const Donate = ({mobileButtons = true, rest}) => <div className={`container mx-auto flex items-center p-2 ${rest}`}>
  <div className={`mx-auto ${mobileButtons ? 'text-lg' : 'text-base'}`}>
    <Link className={`custom-button hover:text-white hover:border-brand-main hover:bg-brand-main hover:border-transparent rounded`} to={'/articles/eid-salat/'}>Salat Schedule</Link>
  </div>
  <div className={`flex mx-auto ${mobileButtons ? 'text-lg' : 'text-base'}`}>
    <Link to={'/page/donate-now/'} className="py-1  px-4 border border-brand-main  hover:border-brand-main bg-brand-main text-white  hover:text-brand-main hover:bg-white rounded uppercase">Donate now</Link>
  </div>
  {/*<div className={'mx-auto'}>*/}
  {/*  <Link to={'/page/donate-now/'} className="custom-button hover:text-white hover:border-brand-main hover:bg-brand-main hover:border-transparent rounded">Donate</Link>*/}
  {/*</div>*/}
</div>

export const Main = ({articles, rest}) => <div className={` items-center text-sm ${rest}`}>
  {articles.filter(post => post.node.mainPitch === true).map((m, i) => <Link
    to={`/articles/${slugify(m.node.title)}`}
    className={'w-full relative m-2 rounded-lg'}
    key={i}>
    {m.node.thumbnailImage && <Img className={'object-cover h-16  w-full rounded-lg'} fluid={m.node.thumbnailImage.localFile.childImageSharp.fluid} alt={m.node.title}/>}
    <div className={'absolute left-0 bottom-0 py-1 pl-2 pr-6 text-white bg-brand-main'} style={{borderRadius: '0 35px 0 8px'}}><p className={'text-xl lg:text-lg xl:text-xl'}>{m.node.title}</p></div>
  </Link>)}
</div>

export const PrayerWidget = () => <div className={'h-full mx-auto'}>
  <iframe title="Prayer Widget" src="https://www.masjidnow.com/embeds/iframe_daily/?masjid_id=5383&options[show_adhan]=true" className={'mx-auto mt-4'} height="100%" width="100%"></iframe>
</div>


export const List = ({links, rest}) => <div className={` ${rest}`}>
  <Donate mobileButtons={true} rest={'md:hidden flex'}/>
  <div className={'flex text-left flex-col w-full    border border-brand-secondary rounded p-2 bg-brand-background'}>
    <div className={'border-b border-brand-main py-2 mb-0 font-bold'}>INFORMATION</div>
    {links.map(({node: l}) =>
      <Link
        className={'hover:text-blue-500 mb-2'}
        to={`/${l.link.childContentfulArticleMarkdownTextNode ? 'articles' : 'page'}/${slugify(l.link.title)}/`}
      >{l.title}</Link>
    )}
  </div>
  <PrayerWidget/>
</div>

export const ArticleGrid = ({articles, count = 1000000}) => <div className={`w-full mx-auto md:min-w-2xl lg:min-w-4xl flex flex-wrap justify-around text-brand-main container mx-auto`}>
  {/*pinned articles*/}
  {articles.filter(post => post.node.mainPitch !== true && post.node.pinnedPost === true).map((r, i) =>
    <ArticleLink article={r} index={i}/>
  )}
  <Main articles={articles} rest={'flex flex-col md:hidden mb-8 mx-4 w-full'}/>
  {/*not pinned articles*/}
  {articles.filter(post => post.node.mainPitch !== true && post.node.pinnedPost !== true).map((r, i) => i < count &&
    <ArticleLink article={r} index={i}/>
  )}
</div>


export const MainPost = ({mainPost, articles, links}) => <div className={'flex flex-row justify-between lg:mt-6 mb-10'}>
  {mainPost.length > 0 ? mainPost.filter((p, i) => i === 0).map(({node: main}, i) =>
    main.isPortrait
      ? <div className={'flex flex-col md:flex-row items-center justify-center w-full lg:w-2/3 mx-auto mb-1 '}>
        <div dangerouslySetInnerHTML={{__html: main.media.childMarkdownRemark.html}} className="main-post-portrait md:w-2/3 px-2 responsive-image"/>
        <div className={'flex flex-col md:w-1/3 h-full text-left px-2 py-4'}>
          <p className={'font-bold mb-2'}>{main.title}</p>
          {main.link && <Link to={`/articles/${slugify(main.link.title)}`} className="w-32 mb-8 text-center py-1 px-4 border border-brand-accent  bg-brand-accent text-brand-main hover:bg-white rounded">Learn more</Link>}
          <p className={'font-bold mb-2 hidden lg:flex'}>Other news</p>
          {articles.filter((post, i) => post.node.mainPitch !== true).map((r, i) =>
            i < 3 && <Link to={`/articles/${slugify(r.node.title)}`} key={i + 'reviews'} className={' w-full mb-1 hidden lg:flex'}>
              <p className={'w-full mx-auto'}>{r.node.title}</p>
            </Link>
          )}
        </div>
      </div>
      : <div className={'flex flex-col items-center justify-center w-full lg:w-2/3 mx-auto mb-4'}>
        <div dangerouslySetInnerHTML={{__html: main.media.childMarkdownRemark.html}} className="responsive-youtube w-full mb-4"/>
        <Link to={`/articles/${slugify(main.link.title)}`} className={'w-full text-lg font-bold text-center'}>{main.title}</Link>
      </div>
  ) : <div className={'flex flex-col md:flex-row items-center justify-center w-full lg:w-2/3 mx-auto mb-1 h-40 md:h-auto text-lg'}>Loading media...</div>}
  <List links={links} rest={'bg-white hidden lg:flex lg:flex-col flex-row p-2 rounded m-0  w-full text-sm max-w-xl lg:max-w-xs mx-auto'}/>
</div>

export const OptionalCallout = ({optionalCallout}) => <div dangerouslySetInnerHTML={{__html: optionalCallout[0].node.description.childMarkdownRemark.html}} className="w-full text-center bg-red-200 container mx-auto my-0  md:my-4 optional-callout"/>


const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNumberOfArticlesInTheMainPage {
        edges {
          node {
            count
          }
        }
      }
      
      allContentfulLandingPageWidgetLinks {
        edges {
          node {
            id
            title
            link {
              ... on ContentfulPages {
                id
                title
                childContentfulPagesMarkdownTextNode {
                  id
                }
              }
              ... on ContentfulArticle {
                id
                title
                childContentfulArticleMarkdownTextNode {
                  id
                }
              }
            }
          }
        }
      }
      
      allContentfulOptionalCalloutWhenNecessary {
        edges {
          node {
            description {
              childMarkdownRemark {
                html
              }
            }
            show
          }
        }
      }
      
      allContentfulMainPost {
        edges {
          node {
            id
            title
            isPortrait
            link {
              title
            }
            media {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      
      allContentfulArticle {
        edges {
          node {
            markdown {
              childMarkdownRemark {
                html
              }
            }
            id
            title
            mainPitch
            pinnedPost
            thumbnailImage {
              localFile {
                url
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const articles = data.allContentfulArticle.edges;
  const mainPost = data.allContentfulMainPost.edges;
  const optionalCallout = data.allContentfulOptionalCalloutWhenNecessary.edges;
  const links = data.allContentfulLandingPageWidgetLinks.edges;
  const articleCount = data.allContentfulNumberOfArticlesInTheMainPage.edges[0].node.count;

  return (
    <Layout>
      <SEO title="Gwinnett Islamic Circle"/>
      {optionalCallout.length > 0 && optionalCallout[0].node.show && <OptionalCallout optionalCallout={optionalCallout}/>}
      <div className={'text-brand-main'}>

        <Donate rest={'flex xl:hidden'}/>

        <div className={'flex flex-col p-2'}>
          <MainPost mainPost={mainPost} articles={articles} links={links}/>

          <ArticleGrid count={articleCount} articles={articles}/>

          {articleCount > 0 && <div className={'mx-auto text-center mb-4 text-brand-main'}>
            <Link className="underline font-bold mx-1 mb-2 w-24 uppercase" to="/articles/page">Read more articles...</Link>
          </div>}
        </div>

        <List links={links} rest={'bg-white flex lg:hidden flex-col p-2 rounded m-0  w-full  mb-8 h-160 text-sm max-w-xl mx-auto'}/>

        <Main articles={articles} rest={'hidden md:flex flex-col lg:flex-row mb-8 mx-4'}/>

      </div>
    </Layout>
  )
}

export default IndexPage
