import React from "react";
import {Link} from "gatsby";
import {slugify} from "../helpers";
import Img from "gatsby-image";

const ArticleLink = ({article, index}) => <Link to={`/articles/${slugify(article.node.title)}`} key={index + 'pinned-articles'} className={'text-center m-2 mb-6 w-full md:w-64'}>
  {article.node.thumbnailImage ? <Img
    imgStyle={{
      objectFit: "cover",
      objectPosition: "50% 0%",
    }}
    className={"w-full md:w-64 mx-auto h-40 mb-4 rounded"}
    alt={article.node.description}
    fluid={article.node.thumbnailImage.localFile.childImageSharp.fluid} /> : <div className={"w-full md:w-64 mx-auto h-40 mb-4 rounded pt-14"}>No image</div>}
  <div className={'w-full md:w-64 mx-auto font-bold h-auto md:h-20 font-thin'}>{article.node.title}</div>
</Link>

export default ArticleLink